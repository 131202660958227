<template>
  <div>
    <div class="page">
      <div class="reward-status-notification" v-if="card.status === 9 && (card.point == card.threshold)">
        <b-card class="mb-2">
            <div class="text-center" v-html="getModuleConfig('reward_card_apply_title')"></div>
        </b-card>
      </div>
      <template v-else>
        <div class="reward-status-notification" v-if="showStatusNotification">
            <b-card class="mb-2">
                <div class="text-center" v-html="showStatusNotification"></div>
            </b-card>
        </div>
      </template>
      <div class="overflow-x-hidden mb-3">
        <b-card class="border border-1 reward-card" no-body>
          <div
            class="
              reward-card__header
              px-3
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div class="reward-card__title">{{ card.name }}</div>
            <div class="d-flex align-items-center">
              <span class="reward-card__header__point">{{ card.point }}</span>
              <span class="small ml-2">點</span>
            </div>
          </div>

          <div class="reward-card__body p-4">
            <div
              v-for="i in card.threshold"
              :key="i"
              class="reward-card__body__point"
              :class="i <= card.point ? 'active' : ''"
            >
              <div
                class="
                  reward-card__body__point__stamp
                  d-flex
                  justify-content-center
                  align-items-center
                "
              >
                <img
                  v-if="i <= card.point"
                  src="./images/stamp.svg"
                  class="img-fluid"
                />
                <span v-else>{{ i }}</span>
              </div>
              <!-- <div class="reward-card__body__point__date">08/09</div> -->
            </div>
          </div>
        </b-card>
      </div>

      <div class="reward-card-list">
        <div class="reward-card-list__item">
          <div>
            <div class="reward-card-list__image">
              <img :src="card.img_url" />
            </div>
            <div class="reward-card-list__stamp">
              <i
                v-for="i in card.threshold"
                :key="i"
                class="fa fa-star"
                :class="card.point >= i && 'active'"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div class="reward-card-list__content">
            <div class="reward-card-list__title">{{ card.name }}</div>
            <div class="reward-card-list__desc">{{ card.content }}</div>
            <div class="reward-card-list__date">
              開始日期{{ formatDate(card.start_date) }}
            </div>
            <div class="reward-card-list__date">
              到期日期{{ formatDate(card.end_date) }}
            </div>
          </div>
        </div>
      </div>

      <SharedButton
        class="s-btn-bg-primary"
        type="button"
        v-if="card.status === 9 && (card.point == card.threshold)"
        @click="$router.push({name:'LiffProjAbbottAndRewardsCardRedeem'})"
      >
        {{ getModuleConfig('reward_card_apply_button_text') }}
      </SharedButton>


      <div class="reward-award mt-3">
        <div class="reward-award__title">點數獎項</div>

        <b-card
          v-for="i in awards"
          :key="i.id"
          class="reward-award-item d-flex flex-nowrap flex-row mt-3"
          no-body
        >
          <div class="reward-award-item__image">
            <img
              class="img-fluid"
              :src="i.img_url"
            />
          </div>
          <div class="flex-fill p-2 d-flex flex-column">
            <div class="reward-award-item__title flex-fill">{{ i.name }}</div>
          </div>
        </b-card>
      </div>

    </div>

    <div class="reward-notice s-p-3" v-if="card.precautions">
      <div class="reward-notice__title">注意事項</div>
      <div v-html="card.precautions"></div>
    </div>
  </div>
</template>

<script>
import SharedButton from "@/components/Page/Liff/Shared/Button";
import abbottAndApi from "@/apis/liff/v2/proj/abbott-and";
import RewardCardMixin from "@/mixins/liff/rewards-card";
import moment from "moment";

export default {
  components: { SharedButton },
  mixins: [RewardCardMixin],
  data() {
    return {
      card: {},
      awards: [],
    };
  },
  computed: {
    showStatusNotification() {
      const config = this.getModuleConfig('status_notification');
      if (config && config.find((c) => c.status == this.card.status)) {
        return config.find((c) => c.status == this.card.status)?.message?.replace(/\r\n|\n\r|\n|\r/g, '<br>');
      } else {
        return null;
      }
    }
  },
  mounted() {
    this.fetchRewards();
  },
  methods: {
    formatDate(date) {
      return moment(date).format("YYYY/MM/DD");
    },
    async fetchRewards() {
      const { data } = await abbottAndApi.getLoyaltyDetail(
        this.$route.params.orgCode,
        this.$route.params.id
      );
        this.card = data.data;
        if (this.card.point >= this.card.threshold) {
          this.card.point = this.card.threshold
        }

        this.card.precautions = this.card.precautions.replace(/\r\n|\n\r|\n|\r/g, '<br>')
        this.awards = [
          {
            img_url: this.card.freebieUrl,
            name: this.card.freebieName,
          }
        ]
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../node_modules/font-awesome/css/font-awesome.min.css";

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__content {
    margin: 10px 20px;
  }
}

.reward-card {
  border-radius: 0.5rem;
  overflow-x: hidden;

  ::v-deep &__carousel {
    //min-height: 260px;
    //overflow: hidden;
    .carousel-inner {
      overflow: visible;
    }
    .carousel-caption {
      top: 0;
      width: 100%;
      left: 0;
      right: 0;
    }

    .carousel-item {
      // min-height: 260px;
      margin-right: -100% !important;
    }

    .carousel-control-prev,
    .carousel-control-next {
      z-index: 10;
      padding: 0.5rem !important;
      width: auto;
      box-shadow: 0px 4px 4px rgba(44, 44, 46, 0.15);
      background: #ffffff;
      border: 1px solid #e5e5ea;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
      border-radius: 5rem;
      top: 55%;
      bottom: auto;
      transform: translateY(-50%);
    }

    .carousel-control-prev {
      left: -0.5rem;
    }
    .carousel-control-next {
      right: -0.5rem;
    }

    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.69434 7.99707C8.69434 8.22559 8.60645 8.43652 8.43066 8.6123L1.87402 15.0195C1.71582 15.1777 1.51367 15.2656 1.28516 15.2656C0.819336 15.2656 0.458984 14.9053 0.458984 14.4307C0.458984 14.2021 0.546875 14 0.696289 13.8418L6.68164 7.99707L0.696289 2.14355C0.546875 1.99414 0.458984 1.7832 0.458984 1.55469C0.458984 1.08887 0.819336 0.728516 1.28516 0.728516C1.51367 0.728516 1.71582 0.816406 1.87402 0.974609L8.43066 7.38184C8.60645 7.55762 8.69434 7.75977 8.69434 7.99707Z' fill='black'/%3E%3C/svg%3E%0A");
    }

    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.305664 7.99707C0.305664 7.75977 0.402344 7.55762 0.578125 7.38184L7.13477 0.974609C7.28418 0.816406 7.48633 0.728516 7.71484 0.728516C8.18945 0.728516 8.5498 1.08887 8.5498 1.55469C8.5498 1.7832 8.45312 1.99414 8.30371 2.14355L2.31836 7.99707L8.30371 13.8418C8.45312 14 8.5498 14.2021 8.5498 14.4307C8.5498 14.9053 8.18945 15.2656 7.71484 15.2656C7.48633 15.2656 7.28418 15.1777 7.13477 15.0195L0.578125 8.6123C0.402344 8.43652 0.305664 8.22559 0.305664 7.99707Z' fill='black'/%3E%3C/svg%3E%0A");
    }

    .carousel-indicators {
      bottom: -2rem;
      > li {
        width: 10px;
        height: 10px;
        background-color: var(--s-primary);
        border-radius: 100%;
      }
    }
  }

  &__header {
    border-top-color: var(--s-primary) !important;
    border-top-width: 5px !important;
    font-weight: 600;
    border-bottom: solid 1px #e5e5ea;

    &__point {
      font-size: 30px;
      color: var(--s-primary);
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0.5rem 1rem;

    &__point {
      opacity: 0.5;
      &__stamp {
        aspect-ratio: 1/1;
        border: dashed 2px #c0c4cc;
        background-color: #f2f2f7;
        color: #979797;
        border-radius: 100%;
        width: 100%;
        font-size: 20px;
        font-weight: 600;
      }

      &__date {
        font-size: 12px;
        text-align: center;
        color: #979797;
        visibility: hidden;
      }
    }

    &__point.active {
      opacity: 1;
      .reward-card__body__point__stamp {
        //content: url(images/stamp.svg);
        background-color: var(--s-primary);
        border: none;
        box-shadow: 0px 4px 4px rgba(44, 44, 46, 0.15);
        padding: 22%;
      }

      .reward-card__body__point__date {
        visibility: visible;
      }
    }

    //     &__point {
    //       &.active {
    //         content: '<svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    // <path d="M14 2L17.708 9.512L26 10.724L20 16.568L21.416 24.824L14 20.924L6.584 24.824L8 16.568L2 10.724L10.292 9.512L14 2Z" fill="white" stroke="#FBFBFB" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    // </svg>';
    //       }
    //     }
  }
}

.reward-award {
  &__title {
    font-weight: 600;
    font-size: 16px;
  }

  &-item {
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: solid 1px #e5e5ea;

    &__image {
      aspect-ratio: 1/1;
      overflow: hidden;
      max-width: 35%;
    }

    &__title {
      font-weight: 600;
      font-size: 18px;
    }

    &__point {
      font-size: 20px;
      font-weight: 600;
      color: var(--s-primary);
    }

    &__stamp {
      background-color: var(--s-primary);
      border-radius: 1rem;
      display: inline-block;
      padding: 5px;
      aspect-ratio: 1/1;
      line-height: 0;
      img {
        width: 10px;
        height: 10px;
      }
    }

    &__button {
      font-size: 13px;
      line-height: 18px;
      padding: 6px 16px;
      font-weight: 600;
      width: auto;

      &:disabled {
        border: solid 1px #c0c4cc !important;
        background-color: #e5e5ea;
        color: #2c2c2e !important;
        opacity: 1;
      }
    }
  }
}

.reward-notice {
  border-top: solid 1px #e5e5ea;
  font-size: 14px;
  font-weight: 400;
  color: #636366;

  &__title {
    font-weight: 600;
    font-size: 16px;
  }
  ol {
    list-style: decimal;
    padding: inherit;

    li {
      margin-bottom: 0.5rem;
    }
    li::marker {
      color: var(--s-primary);
      font-size: 600;
    }
  }
}

.reward-card-list {
  &__item {
    display: flex;
    background: white;
    border: 1px solid #e5e5ea;
    margin-bottom: 12px;
    position: relative;
    overflow: hidden;
    padding: 12px;
    border-radius: 5px;
  }

  &__image {
    max-width: 122px;
    max-height: 122px;
    display: flex;
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__stamp {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    i {
      color: var(--gray);
      margin: 3px;

      &.active {
        color: var(--s-primary);
      }
    }
  }

  &__content {
    width: 100%;
    padding-left: 12px;
  }

  &__date {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #979797;
    margin-bottom: 8px;
    font-family: "PingFang TC";
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #2c2c2e;
    margin-bottom: 8px;
  }
}
</style>

<style scoped>
.reward-card-list__item {
  box-shadow: rgb(from var(--s-primary) r g b / 0.5) 0 2px 8px 0;
}
</style>
